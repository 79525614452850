<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <UserCard></UserCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <Banner></Banner>
        <div class="mt-4 settingsBody rounded card">
          <div class="pt-3 pb-0 ps-4">
            <strong>Zona Peligrosa</strong>
          </div>
          <hr />
          <div class="pb-1">
            <div class="dangerZone m-4 d-flex rounded">
              <div class="m-4 p-3 rounded dangerZoneInner container">
                <h5>Cancelar Subscripción</h5>
                <p class="text-center">
                  La mayoría de funciones se bloquearán. Para desbloquearlas es
                  necesario adquirir una nueva subscripción
                </p>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btn-danger">
                    Cancelar Subscripción
                  </button>
                </div>
              </div>
            </div>
            <div class="dangerZone m-4 d-flex rounded">
              <div class="m-4 p-3 rounded dangerZoneInner container">
                <h5>Eliminar Cuenta</h5>
                <p class="text-center">
                  Una vez eliminada la cuenta no será posible recuperarla. Por
                  favor verifique esta acción
                </p>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btn-danger">
                    Eliminar Cuenta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<script>
import Legal from "@/components/layout/Legal";
import Banner from "@/components/layout/Banner";
import UserCard from "@/views/settings/UserCard";

export default {
  components: { Legal, Banner, UserCard },
  beforeCreate() {
    localStorage.setItem("currentLocation", "Ajustes / Zona Peligrosa");
  },
  name: "Settings Danger"
};
</script>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.dangerZone {
  background-color: rgba(255, 0, 0, 0.281);
}
.dangerZoneInner {
  background-color: var(--secondary-bg);
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>
